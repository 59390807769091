import {Container, Row} from 'react-bootstrap';
import {CourseItemProps} from './interfaces/ICourses';
import RateStars from '../../UI/rate-stars/RateStars';
import SourceViews from '../../UI/source-views/SourceViews';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';
import {FaCircle, FaMinus, FaPlus} from 'react-icons/fa';
import './style/CourseSections.css';
import moment from 'moment';

const CourseSections = ({course}: CourseItemProps) => {
  const [collapsed, setCollapsed] = useState<number | null>(null);

  const changeCollapeHandler = (index: number) => {
    if (collapsed == index) {
      setCollapsed(null);
    } else {
      setCollapsed(index);
    }
  };

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  return (
    <div>
      <Container>
        <Row className="d-flex justify-content-between">
          <div className="course-header">
            <h5>Description</h5>
          </div>
          
          <div className="course-description">
            <div>{showAll ? course.description! : course.description!.slice(0, 300)}</div>
            {!showAll && course.description!.length > 300 && <p className='secondry-color cursor-pointer' onClick={handleShowMore}>See More</p>}
            {showAll && <p className='secondry-color cursor-pointer' onClick={handleShowLess}>See Less</p>}
          </div>
          <h5>{course.provider_data?.name}</h5>
          <div className="course-header mt-3">
            <h5>Include</h5>
          </div>
          <div className="course-features">
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              {course.includes?.hours} Total Hours
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              {course.includes?.quiz} Quiz
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              {course.includes?.assessment} Assesmsnts
            </p>
            {course.includes?.certificate && (
              <p>
                <span>
                  <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
                </span>{' '}
                Certificate
              </p>
            )}
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              {course.includes?.files} Files
            </p>
          </div>
          <div className="rate-content d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <RateStars rateValue={course.ratings} />

              <div className="rate-number mx-2">
                ({course.ratings}) {course.ratings_count} Rate
              </div>
            </div>

            <SourceViews numbers={course.subscribers} />
          </div>

          <div className="course-content">
            <div className="course-header">
              <h5 className="mt-4">Course Content</h5>
              <p className="m-0">
                {course?.sections?.length} sections, {course.lessons_count}{' '}
                lectures
              </p>
            </div>

            <div className="course-content-sections">
              {course?.sections?.map((section, index) => {
                return (
                  <div className="section-container mb-4" key={index}>
                    <div
                      onClick={() => changeCollapeHandler(index)}
                      aria-controls="example-collapse-text"
                      aria-expanded={collapsed == index}
                      className="d-flex justify-content-between align-items-center section-item-collapse">
                      <p className="section-title">{section.title}</p>
                      <div>{collapsed == index ? <FaMinus /> : <FaPlus />}</div>
                    </div>
                    <Collapse in={collapsed == index}>
                      <div id="example-collapse-text">
                        {section.lessons.map((lesson, indexs) => {
                          let type = lesson.content_type;

                          return (
                            <div
                              className="section-item d-flex justify-content-between"
                              key={indexs}>
                              <div className="icons-container d-flex">
                                <div>
                                  {(lesson.content_type === 'video') && (
                                    // <Link to={`${type}`}>
                                    <img alt="img" src="/icons/video.png" />
                                    // </Link>
                                  )}
                                  {lesson.content_type === 'Quizze' && (
                                    // <Link to={`${type}`}>
                                    <img alt="img" src="/icons/quiz.png" />
                                    // </Link>
                                  )}
                                  {lesson.content_type === 'PracticalTask' && (
                                    // <Link to={`${type}`}>
                                    <img src="/icons/task.png" />
                                    // </Link>
                                  )}
                                  {lesson.content_type === 'pdf' && (
                                    <div>
                                      <img alt="img" src="/icons/pdf.png" />
                                    </div>
                                  )}
                                  {(lesson.content_type === 'meet') && (
                                    // <Link to={`${type}`}>
                                    <img alt="img" src="/icons/zoom.png" className='main-icon'/>
                                    // </Link>
                                  )}
                                </div>

                                <div>
                                  <p className="lecture-title">
                                    {lesson.content_type !== 'pdf' && (
                                      <div>{lesson.title}</div>
                                    )}
                                    {lesson.content_type === 'pdf' && (
                                      <div>{lesson.title}</div>
                                    )}
                                  </p>

                                  <p className="lecture-type">
                                    {/* <Link to={`${type}`}> */}
                                    {lesson.content_type === 'video' &&
                                      `Video , ${lesson.duration} Mins`}
                                    {/* </Link> */}
                                    {/* <Link to={`${type}`}> */}
                                    {lesson.content_type === 'Quizze' &&
                                      `Quiz , ${lesson.content_type} Questions`}
                                    {/* </Link> */}
                                    {/* <Link to={`${type}`}> */}
                                    {lesson.content_type === 'PracticalTask' &&
                                      `Upload Practical Task`}
                                    {/* </Link> */}
                                    {lesson.content_type === 'meet' &&
                                      `Meet`}
                                    <div>
                                      {lesson.content_type === 'pdf' &&
                                        `Click To Download PDF`}
                                    </div>
                                  </p>
                                </div>
                              </div>
                               {lesson.content_type == 'meet' && (       
                              <div className='d-flex align-items-center'>
                                {/* <FaLock className='lock-icon' /> */}
                                <div style={{ margin: '0 15px' , color: '#b0b0b0' }}>
                                <div className='mb-1'>
                                  <img
                                    src={'/icons/calendar2.png'}
                                    alt="course-img"
                                    style={{
                                      width: '15px',
                                      marginRight: '10px',
                                    }}
                                  />
                                {`${moment(lesson?.start_date_session).format('YYYY-MM-DD')}`} 
                                
                                </div>

                                <div>
                                  <img
                                    src={'/icons/clock.png'}
                                    alt="course-img"
                                    style={{
                                      width: '15px',
                                      marginRight: '10px',
                                    }}
                                  />
                                 
                                

                                {`${moment(lesson?.start_time, 'HH:mm:ss')
                                        
                                        .format('hh:mm A')} - ${moment(lesson?.end_time, 'HH:mm:ss')
                                        
                                        .format('hh:mm A')}`}

                                  
                                </div>
                                </div>
                                <img
                                  alt="img"
                                  src="/icons/lock.png"
                                  width={25}
                                />
                              </div>
                              )  }
                            </div>
                          );
                        })}
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CourseSections;
