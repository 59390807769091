import {Card, Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FaCircle, FaEdit, FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileSignature, FaFileUpload, FaPaperclip, FaPlay, FaPlaystation, FaRegCheckSquare, FaRegEdit, FaRegFileAlt, FaYoutube, FaYoutubeSquare} from 'react-icons/fa';
import { IoGameControllerOutline } from "react-icons/io5";

import practicalTaskClasses from '../Courses/style/PracticalTaskUplaod.module.css';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  addTrackNote,
  editTrackNote,
  sendInitiativeProf,
  updateProgress,
  updateProgressAutomatic,
} from '../Tracking/Trackings.service';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';

import {getActivityData} from './Initiatives.service';

import moment from 'moment';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import YouTube from 'react-youtube';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import toast, {Toaster} from 'react-hot-toast';
import { getDomain } from '../../helpers/getDomain';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import { colors } from '../../constants/colors';
import ZeroProgressPopover from './components/ZeroProgressPopover';
import { t } from 'i18next';
import { getAllTaskNotes, getTaskDetails } from '../Auth/tasks.services';

const UserTaskAddProgress = () => {
  const videoOptions = {
    playerVars: {
      // You can add additional YouTube player options here
      autoplay: 0,
    },
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [lang, setLang] = useState(queryParams.get('lang') || 'en');

  
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  
  const [progressVal, setProgressVal] = useState<any>(1);
  const [showProgressVal, setShowProgressVal] = useState<any>(false);

  const [showGame, setShowGame] = useState<boolean>(false);

  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);

  const [showAddNote, setShowAddNote] = useState<boolean>(false);

  const [confirmDeleteNote, setConfirmDeleteNote] = useState<boolean>(false);

  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);

  const [allNote, setAllNote] = useState<any>([]);
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { id , memberId } = useParams();

  const [activityData, setActivityData] = useState<any>();

  const [selectedNoteId, setSelectedNoteId] = useState<any>();

  const [selectedNoteValue, setSelectedNoteIdValue] = useState<any>();

  const [profMidea, setprofMidea] = useState<any>();
  
  
  //const daySelected = location.state.daySelected;
  const today = moment().locale('en').format('YYYY-MM-DD');

  const [userActivityId, setUserActivityId] = useState(null);

  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [autoUpdate, setAutoUpdate] = useState<number>(1);

  const [showYoutubeTask, setShowYoutubeTask] = useState<boolean>(false);
  

  const getData = async () => {
    
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('family_member_id', String(memberId));
    let result = await getTaskDetails(id,data)
    if (result.response) {
      dispatch(setLoading(false));
      
      
      setActivityData(result.response.data);
      console.log('tasks details result.response.data')
      /*
      setprofMidea(
        result.response.data?.task_media?.profs.length
          ? result.response.data?.task_media?.profs[0].media_path
          : null,
      );
      */
    }
  };

  const getAllNote = async () => {
    dispatch(setLoading(true)); 
    let data = new FormData();
    data.append('family_member_id', activityData?.family_member_id);
    data.append('activity_id', activityData?.id);
    let result = await getAllTaskNotes(data);
    if (result?.response?.status) {
      console.log('result?.response?.data', result?.response?.data);
        dispatch(setLoading(false));
        setAllNote(result.response.data)
        setShowAllNotes(true)
    }

  };

  const updateAuto = async (id: any, progress: any) => {
    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('progress', progress);
    data.append('media_id', id);
    activityData?.member_family &&
      data.append('family_member_id', activityData?.family_member_id);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      getData();
    }
  };

  const CheckDate = () => {
    return (
      moment(today).isSame(activityData?.task_start_date) ||
      moment(today).isSame(activityData?.task_end_date) ||
      moment(today).isBetween(
        activityData?.task_start_date,
        activityData?.task_end_date,
      )
    );
  };

  

  const deleteNote = async (noteId: any) => {
    let token = localStorage.getItem('token');
    try {
      let result = await axios.delete(
        `${getDomain()}/api/trackingsystem/note/${noteId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.status) {
        setConfirmDeleteNote(false);
        setShowAllNotes(false)
        getData();
      }
    } catch (e) {}
  };

  const addNote = async (actId: any) => {
    let data = new FormData();
    data.append(`note`, selectedNoteValue);
    data.append(`user_tracking_activity_id`, activityData.task_user_activity_id);

    console.log(data);
    let result = await addTrackNote(data);

    if (result.response.status) {
      setShowAddNote(false);
      getData();
    }
  };

  const editNote = async () => {
    let data = new FormData();
    data.append('note', selectedNoteValue);
    data.append('id', selectedNoteId);
    let result = await editTrackNote(data);

    if (result.response.status) {
      setShow(false);
      setShowAllNotes(false)
      getData();
    }
  };

 
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`mt-md-5 position-relative`}>
      <Toaster position="top-right" />
      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={12} className="mt-3">
            <Card style={{
                      padding: '1rem 2rem' ,
                      borderColor: colors.darkGreen ,
                      marginBottom: '2rem',
                      background: activityData?.is_task_completed == 1 ? colors.darkGreen : colors.white }}>
              <div
                className=""
                >
                <div>
                  <img
                    src={activityData?.
                      consultant_avatar  ?? '/icons/manHolder.png'}
                    style={{
                      width: '50px',
                      height: '50px',
                      margin: '0 10px',
                      borderRadius: '50%',
                    }}
                  />
                  <span>{activityData?.consultant_name}</span>
                </div>  

                <h4 style={{margin: '10px'}}>{activityData?.name_en ?? activityData?.name_ar }</h4>

                <hr />

                <div
                className='d-flex justify-content-between'
                style={{margin: '20px 10px 10px 10px'}}
                >
                  <div>
                    
                    <div>
                      {moment(activityData?.task_start_date).locale('en').format('DD MMM YYYY')} - {moment(activityData?.task_end_date).locale('en').format('DD MMM YYYY')}
                    </div>
                  </div>

                  

                  { activityData?.member_family ?   
                  <div>
                    <span
                    style={{padding: '10px' , background: colors.selected , borderRadius: '15px' }}
                    >
                      {activityData?.member_family}
                    </span>
                  </div>
                  : '' }

                </div>
                
              </div>
            </Card>
          </Col>

          <div className="mb-2">
            <h4>About Task</h4>
            <p className={`text-font-muted ${ lang == 'ar' ? 'ar-dir' : '' }`}>{activityData?.long_desc_en}</p>
          </div>

          {activityData?.intro_video ? (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Intro Video')}</h4>
              <div className="media mt-3">
                <div className="d-flex justify-content-between flex-column mb-3">
                  <div>
                  <FaPlay
                          className="activity-icon"
                          style={{
                            color:colors.maincolor ,
                            background:colors.lightred,
                            fontSize:'50px',
                            borderRadius: '10px',
                            padding: '15px',
                            
                          }}
                          />
                    
                  </div>

                  <video
                    width="320"
                    height="240"
                    controls
                    className="mt-4"
                    style={{borderRadius: '15px'}}>
                    <source
                      src={activityData?.intro_video}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {activityData?.task_media?.attachments?.length > 0 && (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Attachments')}</h4>
              <div className="attachments mt-3">
                {activityData?.task_media?.attachments.map((a: any) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3"
                    
                    >
                      <div
                      onClick={() => {
                        if ( CheckDate() ) {
                          
                          window.open(a.media_path, '_blank');
                            updateAuto(a.id, 100);
                        } else {
                          toast.error("Can't open today");
                        }
                      }}
                      >
                      <FaRegFileAlt 
                        className="activity-icon"
                      style={{
                        color:colors.maincolor ,
                        background:colors.lightred,
                        fontSize:'50px',
                        borderRadius: '10px',
                        padding: '10px',
                        
                      }}
                      />
                        <span>{a.name}</span>
                      </div>

                      <div>
                        <div className='d-flex align-items-center'>
                          {a.is_answered ? 
                            <FaRegCheckSquare
                              size={19}
                              color="green"
                              style={{
                                verticalAlign: 'text-bottom',
                                margin: '1.5rem',
                              }}
                            />
                            :
                            ''
                          }

                            <div
                              >
                              <img
                              onClick={ () =>  window.open(`${a.media_path}`,'_blank') }
                                src="/icons/download.png"
                                alt="image"
                                style={{
                                  width: '50px',
                                  margin: '0 20px 0 0',
                                }}
                              />

                                 
                            </div>

                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {activityData?.task_media?.videos.length != 0 && (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Media')}</h4>
              <div className="media mt-3">
                <div
                  className={`d-flex justify-content-between mb-3`}> 
                  <div>
                  <FaPlay
                      className="activity-icon"
                      style={{
                        color:activityData?.task_media?.videos[0].percentage != 0 ? colors.maincolor : colors.blue ,
                        background:activityData?.task_media?.videos[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                        fontSize:'50px',
                        borderRadius: '10px',
                        padding: '15px',
                        
                      }}
                      />
                    <span>{activityData?.task_media?.videos[0].name}</span>
                  </div>

                  <div className='d-flex align-items-center' >

                  

                  {activityData?.task_media?.videos[0].is_answered ? 
                    <FaRegCheckSquare
                      size={19}
                      color="green"
                      style={{
                        verticalAlign: 'text-bottom',
                        margin: '1.5rem',
                      }}
                    />
                  :
                  ''
                  }

                {activityData?.task_media?.videos[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                </div>

                  
                    
                    
                  
                </div>

               
                  
                  <video
                  width="320"
                  height="240"
                  controls
                  className="mt-4"
                  style={{borderRadius: '15px'}}
                  onEnded={ () => { updateAuto(activityData?.task_media?.videos[0].id,100) }} 
                  >
                  <source
                    src={activityData?.task_media?.videos[0].media_path}
                    type="video/mp4"
                  />
                </video>
                
              </div>
            </div>
          )}

          <div className="mb-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="m-0">{t('Notes')}</h4>
              <div
                
                style={{
                  color: '#fb5456',
                  fontWeight: '500',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                  
                }}
                onClick={() => getAllNote()}>
                {t('See all')}
              </div>
            </div>

            {activityData?.notes?.length > 0 && (
              <div>
                {activityData?.notes.map((n: any) => {
                  return (
                    <div className="media mt-3">
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src="/icons/file.png"
                          alt="image"
                          style={{width: '50px', margin: '0 20px 0 0'}}
                        />
                        <div className="w-100">
                          <span>{n.note}</span>
                          <div className="d-flex justify-content-between align-items-center">
                            <p
                              className="m-0 w-100"
                              style={{color: '#b3b3b3'}}>
                              {moment(n.updated_at)
                                .locale('en')
                                .format('D MMM YYYY')}
                            </p>
                            <div className="d-flex align-items-end">
                              <p
                                className="cursor-pointer"
                                style={{color: '#0acad7', margin: '0 20px'}}
                                onClick={() => {
                                  setShow(true);
                                  setSelectedNoteIdValue(n.note);
                                  setSelectedNoteId(n.id);
                                }}>
                                Edit
                              </p>
                              <p
                                className="m-0 secondry-color cursor-pointer"
                                onClick={() => {
                                  setConfirmDeleteNote(true);
                                  setSelectedNoteId(n.id);
                                }}>
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div>
              <p
                className="cursor-pointer secondry-color mt-4"
                style={{fontSize: '18px'}}
                onClick={() => {
                  setShowAddNote(true);
                }}>
                + {t('Add new note')}
              </p>
            </div>
          </div>

        </Row>
      </Container>

      {/*Edit Note*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}>
              {selectedNoteValue}
            </textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => editNote()}>
            <MainButton text={'Edit note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Add Note*/}
      <Modal show={showAddNote} onHide={() => setShowAddNote(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Add Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}></textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() =>
              selectedNoteValue && addNote(activityData.user_activity_id)
            }>
            <MainButton text={'Add note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete note*/}
      <Modal
        show={confirmDeleteNote}
        onHide={() => setConfirmDeleteNote(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected note ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => deleteNote(selectedNoteId)}>
            <MainButton text={'Confirm'} />
          </div>
          <div onClick={() => setConfirmDeleteNote(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Note List*/}
      <Modal show={showAllNotes} onHide={() => setShowAllNotes(false)} centered>
        

        <Modal.Body>
        {allNote.length > 0 && (
          <div>
            {allNote.map((n: any) => {
              return (
                <div className="media mt-3">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src="/icons/file.png"
                      alt="image"
                      style={{width: '50px', margin: '0 20px 0 0'}}
                    />
                    <div className="w-100">
                      <span>{n.note}</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="m-0 w-100"
                          style={{color: '#b3b3b3'}}>
                          {moment(n.note_added_date)
                            .locale('en')
                            .format('D MMM YYYY')}
                        </p>
                        <div className="d-flex align-items-end">
                          <p
                            className="cursor-pointer"
                            style={{color: '#0acad7', margin: '0 20px'}}
                            onClick={() => {
                              setShow(true);
                              setSelectedNoteIdValue(n.note);
                              setSelectedNoteId(n.id);
                            }}>
                            Edit
                          </p>
                          <p
                            className="m-0 secondry-color cursor-pointer"
                            onClick={() => {
                              setConfirmDeleteNote(true);
                              setSelectedNoteId(n.id);
                            }}>
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
         {allNote.length == 0 && (
          <h5 className='text-center'>There is no data found...</h5>
         )}
        </Modal.Body>

       
      </Modal>

    </div>
  );
};

export default UserTaskAddProgress;
