import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './style/PrivateTrackingRequest.module.css'
import { useState } from "react";
import MainButton from "../../UI/main-button/MainButton";
import CustomCheckbox from "./components/CustomCheckbox";
const PrivateTrackingRequest = () => {
    const [show, setShow] = useState<boolean>(true);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleCheckboxChange = (event:any) => {
    const { value, checked } = event.target;
    console.log('value, checked', value, checked )
    if (checked) {
        // If checkbox is checked, add the value to the selectedValues array
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
        // If checkbox is unchecked, remove the value from the selectedValues array
        setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item !== value)
        );
    }

    console.log('selectedValues' , selectedValues )
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )

    };
    
    const checkboxes = [
    { label: 'Ahmed Taha', value: 'option1' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: true },
    { label: 'Ahmed Hamam', value: 'option2' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: false },
    { label: 'Ahmed Elsaid', value: 'option3' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: false },
    ];
   
  return (
  
        <Container className="mt-5">
            <Row>
                <Col md={{span: 6 , offset :3}}>
                    <p style={{ fontSize: '18px'}} className="mb-4">You can request one or more of your or the assigned family member's consltants to follow the system </p>

                    
                    <h4 className="secondry-color mb-4">Me</h4>
                    <div className="box-border mb-4">
                        <div className="d-flex justify-content-between cursor-pointer" onClick={ () => setShow(!show) }>
                            <h5>Consultants List</h5>
                            <div>
                                <img
                                src='/icons/down-arrow.png' 
                                style={{ 
                                    width: '15px',
                                    transform: show ? 'rotate(180deg)' : 'rotate(00deg)'
                                }} 
                                />
                            </div>

                        </div>
                        <hr style={{ paddingBottom: '1rem' }} />
                        { show && <div>
                            {
                                checkboxes.map((checkbox,i) => (
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <img 
                                        src={checkbox.avatar}
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{checkbox.label}</span>
                                    </div>

                                    <div>


                                    <div>
                                        <CustomCheckbox
                                            index={i}
                                            value={checkbox.value}
                                            checked={selectedValues.includes(checkbox.value)}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    </div>

                                    

                                    </div>    
                                </div>
                                ))
                            }
                            </div>
                        }   
                    </div>

                    <div className="box-border mb-3">
                        <h4 className="secondry-color mb-4">Selected Users</h4>
                        {
                            selectedValues.map(value => {
                                return (
                                    <div className="mb-3">
                                        <img 
                                        src={ checkboxes.find( item => item.value == value )?.avatar }
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{ checkboxes.find( item => item.value == value )?.label }</span>
                                    </div>
                                )
                            } )
                        }
                    </div>

                   

                    <div>

                        <Tabs
                        defaultActiveKey="pending"
                        id="uncontrolled-tab-example"
                        className="custom-web-tabs mb-3"
                        >

                            <Tab eventKey="pending" title={<span>Pending <span className={`secondry-color ${classes.TabTitleNumber}`}>1</span> </span>}>
                            {
                                checkboxes.filter((i) => i.assigned == true ).map(item => {
                                    return (
                                        
                                        <div className="mb-3">
                                            <img 
                                            src={ item.avatar }
                                            alt='image'
                                            style={{
                                                width: '60px',
                                                borderRadius: '50%',
                                                height: '60px',
                                                margin:'0 20px 0 0',
                                            }}
                                            />
                                            <span>{ item.label }</span>
                                        </div>
                                    )
                                } )
                            }

                            </Tab>

                            <Tab eventKey="accepted" title={<span>Accepted <span className={`secondry-color ${classes.TabTitleNumber}`}>0</span> </span>}>  
                                
                            </Tab>

                            <Tab eventKey="rejected" title={<span>Rejected <span className={`secondry-color ${classes.TabTitleNumber}`}>0</span> </span>}>  
                                
                            </Tab>
                
                
                        </Tabs>
                    </div>

                    <div>
                        <MainButton text={'Save'} />
                    </div>

                </Col>
            </Row>
        </Container>
    
  );
}

export default PrivateTrackingRequest;