import {useEffect, useRef, useState} from 'react';
import classes from '../style/ActivitiesTab.module.css';
import {Card, Col, Container, Row} from 'react-bootstrap';
import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {FaCheckSquare, FaMarker, FaRegCheckSquare} from 'react-icons/fa';
import moment from 'moment';
import {getDaysInRange} from '../../../helpers/getDayInRange';
import {TrackingItemProps} from '../interfaces/ITrackings';
import {useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../../UI/main-button/MainButton';
import {updateProgress} from '../Initiatives.service';
import {useTranslation} from 'react-i18next';
//import 'moment/locale/ar';

const ActivitiesTab = ({
  tracking,
  daySelected,
  userId,
  setDaySelected,
}: TrackingItemProps) => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const days = getDaysInRange([
    {
      start: moment(tracking.start_date).locale('en'),
      end: moment(tracking.end_date).locale('en'),
    },
  ]);

  const trackingActivities = tracking.activities || [];

  const dateToday = moment().format('YYYY-MM-DD');

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const [show, setShow] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [userActivityId, setUserActivityId] = useState(null);

  const [activeDay, setActiveDay] = useState(daySelected);

  const today = moment().locale('en').format('YYYY-MM-DD');

  const handleClick = (e: any) => {
    const bar = e.currentTarget;

    const clickPosition = e.clientX - bar.getBoundingClientRect().left;

    const totalWidth = bar.clientWidth;
    let newProgress = (clickPosition / totalWidth) * 100;
    if (newProgress >= 95) {
      newProgress = 100;
    }
    setProgress(Math.floor(newProgress));
    if (newProgress >= 90) {
      setStyleProgress(85);
    } else {
      setStyleProgress(Math.floor(newProgress));
    }
  };

  const updateManualProgress = async () => {
    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', String(Math.floor(progress)));
    let result = await updateProgress(data);

    if (result.response.status) {
      setShow(false);
      if (setDaySelected) setDaySelected(daySelected!);
    }
  };
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      ScrollTo(null);
    }, 1000);
  }, []);

  const ScrollTo = (day: string | null) => {
    let startDate = moment(tracking.start_date).locale('en');
    const indexToScroll =
      Math.abs(startDate.diff(moment(day ? day : daySelected), 'days')) + 2;

    if (containerRef.current) {
      const elementToScrollTo = containerRef.current.children[
        indexToScroll
      ] as HTMLElement;
      elementToScrollTo.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  };

  return (
    <>
      <Container>
        <div ref={containerRef} className="d-flex mt-4 hideScroll">
          {days.map((d, i) => {
            return (
              <div
                className={`${classes.Day} ${
                  d.date == daySelected ? classes.ActiveDay : ''
                }`}
                onClick={() => {
                  if (setDaySelected) {
                    setActiveDay(d.date);
                    setDaySelected(d.date);
                  }
                  ScrollTo(d.date);
                }}>
                <div>{d.dayName}</div>
                <div style={{fontSize: '1rem'}}>
                  {moment(d.date).locale('ar').format('ddd')}
                </div>
                <div style={{fontSize: '1rem'}}>
                  {moment(d.date).format(daySelected === d.date ? 'D' : 'D')}
                </div>
              </div>
            );
          })}
        </div>

        <Row>
          <Col md="12" className="mt-5 mb-5">
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{padding: '1rem 2rem'}}>
                <div style={{fontSize: '22px'}}>{t('DailyScore')}</div>
                <div>
                  <CircleProgress value={tracking.daily_progress} />
                </div>
              </div>
            </Card>
          </Col>

          {trackingActivities.map((a: any, i) => {
            return (
              <Col
                md="12"
                className="mt-4"
                key={i}
                onClick={() => {
                  navigate(
                    `/initiative-activity/${a.activity_id}/${a.tracking_id}/${dateToday}/${userId}/${daySelected}/add-progress?lang=${a.lang}`,
                    {
                      state: {
                        item: tracking,
                        daySelected: daySelected,
                      },
                    },
                  );
                }}>
                <Card
                  style={{
                    borderColor: 'green',
                    padding: '1rem 2rem',
                    cursor: 'pointer',
                  }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>
                        {' '}
                        {a?.is_completed == 1 && (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{verticalAlign: 'text-bottom'}}
                          />
                        )}
                        <span
                          style={{
                            fontWeight: '500',
                            fontSize: '18px',
                          }}>
                          {a.name}
                        </span>
                      </div>
                      <div
                        style={{fontSize: '14px'}}
                        className={`${
                          moment(a.end_date).isSame(today)
                            ? 'secondry-color'
                            : ''
                        } font-weight-bold`}>
                        {t('End Date')}:{' '}
                        {moment(a.end_date).isSame(today)
                          ? t('Today')
                          : a.end_date}
                      </div>
                    </div>
                    {a.auto_progress_update == 0 && today == daySelected ? (
                      <div
                        style={{
                          padding: '5px 15px',
                          borderRadius: '7px',
                          backgroundColor: '#fee',
                          color: '#fb5456',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onClick={() => {
                          setUserActivityId(a.user_activity_id);
                          setShow(true);
                        }}>
                        Update
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      onClick={() => {
                        navigate(
                          `/tracking-system-activity/${a.activity_id}/${a.tracking_id}/${a.end_date}/${userId}/add-progress/`,
                          {
                            state: {
                              item: tracking,
                            },
                          },
                        );
                      }}>
                      <CircleProgress value={a.progress} />
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>

      {/*Update Progress manually*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '10px',
              backgroundColor: '#ccc',
              position: 'relative',
              borderRadius: '15px',
              cursor: 'pointer',
              marginTop: '25px',
            }}>
            <span
              className={classes.ProgressValue}
              style={{
                color: '#fff',
                background: '#fb5456',
                borderBottom: '10px solid #fb5456',
                left: `${styleProgress}%`,
              }}>
              {progress}%
            </span>
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: 'green',
                position: 'absolute',
              }}></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>0%</div>
            <div>100%</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => updateManualProgress()}>
            <MainButton text={'Done'} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActivitiesTab;
