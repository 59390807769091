import {Col, Container, Row, Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/AddAvailabilitySettings.module.css';
import {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {
  getAvailabilityStatus,
  getPrices,
  setAvailabilityStatus,
  setPrices,
} from './Availability.service';
import {useNavigate} from 'react-router-dom';

const AddAvailabilitySettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean | null>(null);
  const [price30, setPrice30] = useState('0');
  const [price60, setPrice60] = useState('0');
  const [price90, setPrice90] = useState('0');
  const [price120, setPrice120] = useState('0');
  const [price150, setPrice150] = useState('0');
  const [price180, setPrice180] = useState('0');
  const [duration30, setDuration30] = useState(false);
  const [duration60, setDuration60] = useState(false);
  const [duration90, setDuration90] = useState(false);
  const [duration120, setDuration120] = useState(false);
  const [duration150, setDuration150] = useState(false);
  const [duration180, setDuration180] = useState(false);
  const [marginHours, setMarginHours] = useState('0');
  const [validation, setValidation] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [first_session_free, setFirst_session_free] = useState(0);
  const [cantDeletePeriod, setCantDeletePeriod] = useState(false);
  useEffect(() => {
    getDisable();
    ReadData();
  }, []);

  const ApplyData = async () => {
    setValidation('');
    if (duration30 && price30 == '0') {
      setValidation('Must add price for active periods');
    } else if (duration60 && price60 == '0') {
      setValidation('Must add price for active periods');
    } else if (duration90 && price90 == '0') {
      setValidation('Must add price for active periods');
    } else if (duration120 && price120 == '0') {
      setValidation('Must add price for active periods');
    } else if (duration150 && price150 == '0') {
      setValidation('Must add price for active periods');
    } else if (duration180 && price180 == '0') {
      setValidation('Must add price for active periods');
    } else {
      dispatch(setLoading(true));
      let data = new FormData();
      data.append('first_session_free', String(first_session_free));
      if (duration30) {
        data.append('duration30', '30');
        data.append('currency30', price30);
      }
      if (duration60) {
        data.append('duration60', '60');
        data.append('currency60', price60);
      }
      if (duration90) {
        data.append('duration90', '90');
        data.append('currency90', price90);
      }
      if (duration120) {
        data.append('duration120', '120');
        data.append('currency120', price120);
      }
      if (duration150) {
        data.append('duration150', '150');
        data.append('currency150', price150);
      }
      if (duration180) {
        data.append('duration180', '180');
        data.append('currency180', price180);
      }
      data.append('marginhours', marginHours);
      console.log('dara', data);
      try {
        let result = await setPrices(data);
        console.log(result);
        if (result.response.status) {
          dispatch(setLoading(false));
          navigate(-1);
        } else {
          dispatch(setLoading(false));
          setCantDeletePeriod(true);
        }
      } catch (e) {
        console.log({e});
        dispatch(setLoading(false));
      }
    }
  };
  const ReadData = async () => {
    dispatch(setLoading(true));

    try {
      let result = await getPrices();
      if (result.response.status && result.response.data) {
        setMarginHours(JSON.stringify(result?.response?.data?.marginhours));
        setFirst_session_free(result?.response?.data?.first_session_free);
        if (
          result?.response?.data?.duration30 &&
          result?.response?.data?.duration30 !== 0
        ) {
          setDuration30(true);
          setPrice30(JSON.stringify(result?.response?.data?.currency30));
        }
        if (
          result?.response?.data?.duration60 &&
          result?.response?.data?.duration60 !== 0
        ) {
          setDuration60(true);
          setPrice60(JSON.stringify(result?.response?.data?.currency60));
        }
        if (
          result?.response?.data?.duration90 &&
          result?.response?.data?.duration90 !== 0
        ) {
          setDuration90(true);
          setPrice90(JSON.stringify(result?.response?.data?.currency90));
        }
        if (
          result?.response?.data?.duration120 &&
          result?.response?.data?.duration120 !== 0
        ) {
          setDuration120(true);
          setPrice120(JSON.stringify(result?.response?.data?.currency120));
        }
        if (
          result?.response?.data?.duration150 &&
          result?.response?.data?.duration150 !== 0
        ) {
          setDuration150(true);
          setPrice150(JSON.stringify(result?.response?.data?.currency150));
        }
        if (
          result?.response?.data?.duration180 &&
          result?.response?.data?.duration180 !== 0
        ) {
          setDuration180(true);
          setPrice180(JSON.stringify(result?.response?.data?.currency180));
        }
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  const setDisable = async (i: any) => {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('disable', i);
    let result = await setAvailabilityStatus(data);
    if (result.response.status) {
      dispatch(setLoading(false));
      setShow(i);
      getDisable();
    }
  };
  const getDisable = async () => {
    let result = await getAvailabilityStatus();
    if (result.response.status) {
      setDisabled(result.response.data);
    }
  };

  return (
    <div className="mt-md-5 mb-5">
      <Container>
        <div className="text-center">
          <img src="/icons/hourglass.png" style={{maxWidth: '200px'}} />
          <h3 className="w-100 mb-3 font-weight-bold mt-3">
            Add Availability Settings
          </h3>
          <p className="text-font-muted">You can add more than one duration</p>
        </div>

        <Row>
          <Col md={{span: 6, offset: 3}}>
            <div
              className={`${classes.Container} d-flex justify-content-between align-items-center`}>
              <div>Enable Availability</div>
              <div>
                <div className={`${classes.enableAvailability}`}>
                  <input
                    type="checkbox"
                    id="enable-availabilty"
                    onChange={() => {
                      disabled ? setDisable(false) : setDisable(true);
                    }}
                    checked={!disabled}
                  />
                  <label id="enable-availabilty"></label>
                </div>
              </div>
            </div>
          </Col>

          <Col md={{span: 6, offset: 3}}>
            <div className={`mt-4`}>
              <div>
                <div className={`d-flex justify-content-between`}>
                  <p>Session Duration</p>
                  <p>Session Price</p>
                </div>

                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'30'}>
                      <Form.Check.Input
                        checked={duration30}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration30(!duration30);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'30 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price30}
                        maxLength={4}
                        onChange={i => setPrice30(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'60'}>
                      <Form.Check.Input
                        checked={duration60}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration60(!duration60);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'60 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price60}
                        maxLength={4}
                        onChange={i => setPrice60(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'90'}>
                      <Form.Check.Input
                        checked={duration90}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration90(!duration90);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'90 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price90}
                        maxLength={4}
                        onChange={i => setPrice90(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'120'}>
                      <Form.Check.Input
                        checked={duration120}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration120(!duration120);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'120 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price120}
                        maxLength={4}
                        onChange={i => setPrice120(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'150'}>
                      <Form.Check.Input
                        checked={duration150}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration150(!duration150);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'150 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price150}
                        maxLength={4}
                        onChange={i => setPrice150(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'180'}>
                      <Form.Check.Input
                        checked={duration180}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration180(!duration180);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'180 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price180}
                        onChange={i => setPrice180(i.target.value)}
                        maxLength={4}
                      />{' '}
                      USD
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={{span: 6, offset: 3}}>
            <div
              className={`${classes.Container} d-flex justify-content-between align-items-center`}>
              <div>First session will be free</div>
              <div>
                <Form.Check type="checkbox" id={'is-free'}>
                  <Form.Check.Input
                    checked={first_session_free == 1}
                    type="checkbox"
                    isValid
                    onChange={() => {
                      setFirst_session_free(first_session_free ? 0 : 1);
                    }}
                  />
                  <Form.Check.Label></Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          </Col>

          <Col md={{span: 6, offset: 3}}>
            <p className="text-font-muted mt-4 mb-4">
              You can stop the session booking by specifying the number of hours
              before it starts
            </p>
          </Col>

          <Col md={{span: 6, offset: 3}}>
            <div
              className={`${classes.TimeBoxContainer} d-flex justify-content-between`}>
              <div>
                <input
                  value={marginHours}
                  type="text"
                  onChange={i => setMarginHours(i.target.value)}
                  maxLength={4}
                />
              </div>
              <div>hour</div>
            </div>
          </Col>
          <Col md={{span: 6, offset: 3}}>
            <div className="mt-4 mb-4 danger-title">{validation}</div>
          </Col>
          <Col md={{span: 6, offset: 3}}>
            <MainButton text={'Done'} onClick={ApplyData} />
          </Col>
        </Row>
      </Container>

      {/*switch enable availabilty*/}
      <Modal show={show !== null} onHide={() => setShow(null)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/nurabi.png" style={{maxWidth: '60px'}} />
            <h5 className="w-100 mt-4">
              {show
                ? 'Sessions requests have been suspended'
                : 'Sessions requests have been opened again'}
            </h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setShow(null)} className="w-100">
            <MainButton text={'Ok'} />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={cantDeletePeriod}
        onHide={() => setCantDeletePeriod(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/nurabi.png" style={{maxWidth: '60px'}} />
            <h5 className="w-100 mt-4">
              You cannot delete this period until you delete its associated
              availability periods
            </h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setCantDeletePeriod(false)} className="w-100">
            <MainButton text={'Ok'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAvailabilitySettings;
