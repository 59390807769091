import {Container} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserInitiativesList from './UserTrackingsList';
import UserInitiativesDiscover from './UserTrackingsDiscover';
const UserTrackings = () => {

  return (
    <Container>
      <h1 className="page-title">Follow-up Systems</h1>

      {/* User Courses Tabs */}
      <Tabs
        defaultActiveKey="userTackingSystems"
        id="uncontrolled-tab-example"
        className="custom-web-tabs mb-5"
        >
        <Tab eventKey="userTackingSystems" title="My Follow-up Systems">
         <UserInitiativesList />
        </Tab>
        <Tab eventKey="nurabiTrackingSystems" title="Discover Systems">
          <UserInitiativesDiscover />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default UserTrackings;
