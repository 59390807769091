import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {TrackingItemProps} from '../interfaces/ITrackings';
import classes from '../style/TrackingCard.module.css';
import {useNavigate} from 'react-router-dom';
import {getUserId} from '../../../auth/utiles';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
const TrackingCard = ({
  tracking,
  isPackage
}: TrackingItemProps) => {
  const navigate = useNavigate();

  const userId = getUserId() ?? '';

  return (
    <div
      className={`${classes.Item} d-flex row`}
      style={tracking.lang === 'ar' ? { direction: 'rtl' , 'textAlign' : 'right' } : {}}
      onClick={() => {
        if( isPackage ){
          navigate(`/user/tracking-packages/${tracking.id}/package/levels`);
          
        }else{
          if( (tracking.tracking_type && tracking.tracking_type == 'single') || tracking.level ){

            if( tracking.selected_enrolled_user_id ){
              navigate(`/user/tracking/${tracking.id}/details/enrolled/${tracking.selected_enrolled_user_id}`,
              
              {
                state: {
                  item: tracking,
                },
              });
            }else{
              navigate(`/user/tracking/${tracking.id}/details`);
              
            }
          }
        }
      }}
     
      >
      <h5 className="font-weight-bold p-0 mb-3">{tracking.name}</h5>
      
      {
        isPackage
        ? 
        <div className={`${classes.Owner}`} style={tracking.lang === 'ar' ? { right: 'initial' , left : '0px' , borderRadius: '10px 0px' } : {}} >levels</div>
        :
        ''
      } 
     
      <div className={`${classes.Image} col-4`}>
      <img
          src={tracking.image ? tracking.image : '/nurabi.png'}
          alt="course-img"
          style={{
            width: tracking.image ? '180px' : '100px',
            height: tracking.image ? '160px' : 'auto',
          }}
        />
      </div>
      <div
        className={`${classes.Details} col-8 d-flex flex-column justify-content-between`}>
        <div style={{margin: '0 5px'}}>
          

          <h4 className={`${classes.Provider}`} style={tracking.lang === 'ar' ? { direction: 'rtl' } : {}}>
          {tracking.provider_name ? `${tracking.provider_name}` : `Nurabi`}{' '}
          </h4>

        

          {
          
            tracking.enrolled_members
            
            ?
              
            <div className="d-flex text-center">
              {
              tracking.enrolled_members.length > 0
            
                ? tracking.enrolled_members.map((item, index) => (
                    <div key={index} style={{margin: '0 10px'}}>
                      <img
                        src={item.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '20px',
                          height: '25px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                      <h6>
                        {item.username.length > 7
                          ? item.username.substring(0, 7) + ' ...'
                          : item.username}
                      </h6>
                    </div>
                  ))
                : ''}
            </div>

            : 
            
            ''
          
          }

          
          
        </div>
        
      </div>
    </div>
  );
};

export default TrackingCard;
