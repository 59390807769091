import { colors } from "../../constants/colors";

const SearchButton = ({getData}:any) => {

  return (
    <span
    onClick={ () => getData() }
    style={{
        color:'#fff',
        background:colors.green,
        borderRadius:'10px',
        padding:'5px 15px',
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
    }}
    >Search</span>
  );
};

export default SearchButton;
