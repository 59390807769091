import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import TrackingDetailsCard from '../Tracking/components/TrackingDetailsCard';
import ActivitiesTab from './components/ActivitiesTab';
import RankingTab from '../Tracking/components/RankingTab';
import PersonalityAnalysisCard from '../Quiz/components/PersonalityAnalysisCard';
import ResultProgress from '../Tracking/components/ResultProgress';
import AreaChart from '../Tracking/components/AreaChart';
import {FaCircle} from 'react-icons/fa';
import {
  assignAndStartTrack,
  getTrackingsDetailsAfterEnroll,
  getmemberForAssign,
} from '../Tracking/Trackings.service';
import moment from 'moment';
import {TrackingType} from './interfaces/ITrackings';
import axios from 'axios';
import MainButton from '../../UI/main-button/MainButton';
import Modal from 'react-bootstrap/Modal';
import CancelButton from '../../UI/cancel-button/CancelButton';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import Card from 'react-bootstrap/Card';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {
  getTrackData,
  getDataAfterEnroll,
  repeatTrack,
} from './Trackings.service';
import InitiativeDetailsCard from './TrackingDetailsCard';
import {useNavigate} from 'react-router-dom';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import {NavLink} from 'react-router-dom';
import { getDomain } from '../../helpers/getDomain';

const TrackingDetailsAfterEnroll = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {id} = useParams();

  const location = useLocation();

  const [item, setItem] = useState(location.state.item);

  const start_date = location.state.start_date;
  const end_date = location.state.end_date;
  const trackingDetails = location.state.tracking;

  const userId = item?.selected_enrolled_user_id ?? localStorage.getItem('id');

  let token = localStorage.getItem('token');
  const [data, setData] = useState<TrackingType | undefined>();
  const [daysProgress, setDaysProgress] = useState<any>([]);
  const [report, setReport] = useState<any>();

  const [daySelected, setDaySelected] = useState<string | undefined>();

  const [members, setMembers] = useState<any>([]);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [member, setMember] = useState(null);

  const [selectedUsername, setSelectedUsername] = useState(null);

  const [selectedUserAvatar, setSelectedUserAvatar] = useState(null);

  const [selectedUserId, setSelectedUserId] = useState(null);

  const [showConsultants, setShowConsultants] = useState(false);

  

  const getAssignedUserVal = (e: any) => {
    console.log(e.target.value);
    setMember(e.target.value);
  };

  const redirectWithNewId = () => {
    setShowAssignUsers(false);

    navigate(
      `/user/initiative/${id}/details/enrolled/${member}`,

      {
        state: {
          start_date,
          end_date,
          item: trackingDetails,
        },
      },
    );

    getReport();
    getData(null);
  };

  const [showConfirmRepeat, setShowConfirmRepeat] = useState<boolean>(false);

  const repeatTracking = async () => {
    let trackId = id;
    let currentUserId = data?.selected_enrolled_user_id;

    let dataForm = new FormData();
    dataForm.append('current_user_id', String(currentUserId));
    let result = await repeatTrack(trackId, dataForm);
    if (result?.response?.status) {
      setShowConfirmRepeat(false);
      if (
        moment(new Date(), 'YYYY-MM-DD').isSameOrBefore(
          moment(data?.end_date, 'YYYY-MM-DD'),
          'day',
        )
      ) {
        setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
        getData(moment().locale('en').format('YYYY-MM-DD'));
      }
    }
  };

  const series = [
    {
      name: 'Network',
      data: daysProgress,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      opacity: 0.8,
      type: 'pattern',
      pattern: {
        style: ['verticalLines', 'horizontalLines'],
        width: 5,
        height: 6,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    theme: {
      palette: 'palette1',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: '',
      },
    },
  };

  useEffect(() => {
    getReport();
    getData(null);
  }, []);

  const getData = async (date: string | null) => {
    dispatch(setLoading(true));
    let currentUserId = userId ?? localStorage.getItem('id');
    let result = await getTrackData(id, currentUserId);

    let today = moment().locale('en').format('YYYY-MM-DD');
    let start = moment(start_date).locale('en');
    let end = moment(end_date).locale('en');
    let check =
      moment(today).isBetween(start, end) || moment(today).isSame(end);

    let theDate = daySelected
      ? daySelected
      : check
      ? moment().locale('en').format('YYYY-MM-DD')
      : moment(start_date).locale('en').format('YYYY-MM-DD');
    console.log('theDate', theDate);
    console.log('start_date', start_date);
    console.log('end_date', end_date);

    setDaySelected(date ?? theDate);
    console.log('date', date);

    if (result.response) {
      console.log('track result.response', result.response);

      try {
        let selectedId = null;
        if (member) {
          selectedId = member;
        } else {
          selectedId = userId;
        }

        let result = await getDataAfterEnroll(
          id,
          date
            ? date
            : theDate
            ? theDate
            : moment().locale('en').format('YYYY-MM-DD'),
          selectedId,
          null,
        );
        if (result.response) {
          dispatch(setLoading(false));
          setData(result.response.data);
          setMembers(result.response.data.enrolled_members);
          console.log('selected user name', result.response.data);
          setSelectedUsername(result.response.data.selected_user_name);
          setSelectedUserAvatar(result.response.data.selected_user_avatar);
          setSelectedUserId(result.response.data.selected_enrolled_user_id);
          setMember(result.response.data.selected_enrolled_user_id);
        }
      } catch (error) {
        // console.log('gugu', error);
      }
    }
  };

  const getTrackDetails = async (date: string | null) => {};

  const getReport = async () => {
    let api = `${getDomain()}/api/trackingsystem/report/${id}?current_user_id=${userId}`;

    try {
      let result = await axios({
        url: api,
        method: 'get',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('reports', result?.data?.data);
      if (result.data.status) {
        setReport(result?.data?.data);
        let modfiedArray = [];
        for (const key in result?.data?.data.progress_per_days) {
          const value = result?.data?.data.progress_per_days[key];
          modfiedArray.push({
            x: `${moment(key).format('MMM DD YYYY')}`,
            y: value,
          });
        }
        console.log('modfiedArray', modfiedArray);
        setDaysProgress(modfiedArray);
      }
    } catch (error) {}
  };

  const StartTrack = async () => {
    dispatch(setLoading(true));
    try {
      let selectedId = null;
      if (member) {
        selectedId = member;
      } else {
        selectedId = userId;
      }

      let data = new FormData();

      data.append('tracking_id', String(id));
      data.append('start_tracking', '1');
      data.append('members[0]', selectedId);

      let result = await assignAndStartTrack(data);
      if (result.response) {
        if (result.response.code == 202) {
          console.log('result.response.msg', result.response.msg);
          dispatch(setLoading(false));
        } else {
          setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
          getData(moment().locale('en').format('YYYY-MM-DD'));
        }
      } else dispatch(setLoading(false));
    } catch (e) {
      console.log({e});
    }
  };

  return (
    <div className="mt-md-5">
      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={4} className="mt-3 mb-3">
            <Card className="text-center">
              <Card.Body className="mt-3">
                <Card.Title>
                  <div className="mb-3">{data?.name}</div>
                </Card.Title>

                <Card.Title>
                  <div className="d-flex justify-content-center align-items-center">
                    <span style={{fontSize: '1.2rem', marginRight: '5px'}}>
                      Total Score
                    </span>
                    <span>
                      <CircleProgress
                        value={
                          data?.total_progress % 1 === 0
                            ? data?.total_progress.toFixed(0)
                            : data?.total_progress.toFixed(1)
                        }
                      />
                    </span>
                  </div>
                </Card.Title>

                <Card.Title>
                  <div className="p-3">
                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      {data?.has_unlimited_enrollment == 1 ? (
                        <NavLink to="/">
                          <img
                            src="/icons/assignment.png"
                            style={{
                              width: '25px',
                              marginRight: '10px',
                            }}
                          />

                          <span
                            className="secondry-color"
                            style={{fontSize: '17px'}}>
                            Assign Family
                          </span>
                        </NavLink>
                      ) : (
                        ''
                      )}

                  <div className='d-none'>
                    <img
                      src="/icons/reload2.png"
                      style={{
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />

                    <span
                      className="secondry-color"
                      style={{fontSize: '17px', cursor: 'pointer'}}
                      onClick={() => {
                        setShowConsultants(true);
                      }}>
                      Request Supervision
                    </span>
                  </div>

                      {data?.has_started_tracking == 1 && (
                        <div>
                          <img
                            src="/icons/reload2.png"
                            style={{
                              width: '25px',
                              marginRight: '10px',
                            }}
                          />

                          <span
                            className="secondry-color"
                            style={{fontSize: '17px', cursor: 'pointer'}}
                            onClick={() => {
                              setShowConfirmRepeat(true);
                            }}>
                            Repeat
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} lg={8}>
            <div
              style={{
                padding: '1rem 2rem',
                border: '1px solid #bcbcbc',
                borderRadius: '15px',
              }}
              onClick={() => setShowAssignUsers(true)}
              className="d-flex justify-content-between align-items-center mb-3 mt-3">
              <div>
                <img
                  src={selectedUserAvatar ?? '/icons/manHolder.png'}
                  style={{
                    width: '50px',
                    height: '50px',
                    marginRight: '10px',
                    borderRadius: '50%',
                  }}
                />
                <span>{selectedUsername}</span>
              </div>
              <p className="m-0" style={{color: 'red', cursor: 'pointer'}}>
                <strong>Switch user</strong>
              </p>
            </div>
            <Tabs
              defaultActiveKey={'activities'}
              id="uncontrolled-tab-example"
              className="consultant-tabs">
              <Tab eventKey="activities" title="Activities">
                {data?.has_started_tracking ? (
                  <ActivitiesTab
                    tracking={data ? data : item}
                    daySelected={daySelected}
                    userId={userId}
                    setDaySelected={i => {
                      getData(i);
                      setDaySelected(i);
                    }}
                  />
                ) : (
                  ''
                )}
              </Tab>

              <Tab eventKey="ranking" title="Ranking">
                {data?.has_started_tracking ? (
                  <RankingTab userId={userId} />
                ) : (
                  ''
                )}
              </Tab>

              <Tab eventKey="reports" title="Reports">
                {data?.has_started_tracking ? (
                  <Row>
                    <Col sm="6">
                      <ResultProgress
                        report={report?.completed}
                        title={'Completed'}
                        color={'#39b970'}
                        backColor={'#39b970'}
                      />
                    </Col>

                    <Col sm="6">
                      <ResultProgress
                        report={report?.remaining}
                        title={'Remaining'}
                        color={'red'}
                        backColor={'#fb5456'}
                      />
                    </Col>

                    <Col md="12">
                      {daysProgress.length > 0 ? (
                        <Card className="text-center main-card">
                          <Card.Body className="mb-4 mt-3">
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              height={350}
                            />
                          </Card.Body>
                        </Card>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Tab>

              <Tab eventKey="about" title="About">
                <div>
                  <h4>About System</h4>
                  <p className="text-font-muted">{data?.description}</p>
                </div>

                <div>
                  <h4>Provided By</h4>
                  <p className="text-font-muted">{data?.provider_name}</p>
                </div>

                <div>
                  <h4>Fields</h4>

                  {data?.fields &&
                    data?.fields.map((d: any, i: number) => (
                      <>
                        <p style={{margin: '10px 0 5px'}}>
                          <FaCircle color="fb5456" />
                          <span style={{marginLeft: '10px'}}>
                            {d.name_en ?? d.name_ar}
                          </span>
                        </p>

                        {d.goals && (
                          <div className="goals" style={{marginLeft: '20px'}}>
                            {d.goals.map((goal: any, index: number) => {
                              return (
                                <div>
                                  <FaCircle color="#9f9f9f" size={12} />
                                  <span style={{marginLeft: '10px'}}>
                                    {goal.name_en ?? goal.name_ar}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </Tab>

              <Tab eventKey="history" title="History">
                {data?.has_started_tracking ? <h1>History List</h1> : ''}
              </Tab>
            </Tabs>

            {data?.has_started_tracking == 0 && (
              <MainButton
                text="Start Now"
                style={{marginTop: 30}}
                onClick={() => {
                  StartTrack();
                }}
              />
            )}
          </Col>
        </Row>
      </Container>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className={classes.AuctionType}>
            <ul className="relative mb-3 w-full flex flex-wrap items-stretch justify-center p-0 hideScroll">
              {members.map((e: any, i: any) => (
                <li className="text-center" key={i}>
                  <input
                    type="radio"
                    name="test"
                    id={`name-${i}`}
                    value={e.id}
                    onChange={getAssignedUserVal}
                  />

                  <label htmlFor={`name-${i}`}>
                    <div>
                      <img
                        src={e.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <h6>{e.username}</h6>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={'Switch Now'}
              onClick={() => {
                redirectWithNewId();
              }}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Delete all availabilty for selected day*/}
      <Modal
        show={showConfirmRepeat}
        onHide={() => setShowConfirmRepeat(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <p className="w-100 mb-4" style={{fontSize: '22px'}}>
              If you repeat this system now, you will loose any progress on it
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => repeatTracking()}>
            <MainButton text={'Repeat'} />
          </div>
          <div onClick={() => setShowConfirmRepeat(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackingDetailsAfterEnroll;
