import {useEffect, useState , useRef } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import classes from '../../components/navigation-header-v2/NavigationHeaderV2.module.css';
import {
  getAllNotifications,
  readAllNotification,
  readOneNotification,
} from './Notiications.services';
import moment from 'moment';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import colors from 'react-multi-date-picker/plugins/colors';

const Notifications = () => {
  const notificationBoxRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showLoadingMsg, setShowLoadingMsg] = useState(false);
  const [noReadCount, setNoReadCount] = useState(0);

  const fetchData = async () => {
    dispatch(setLoading(true));

    try {
      const result = await getAllNotifications(page);
      if (result.data.status) {
        setNoReadCount(result.data.notificationsCount);
        console.log('notifcation list', result);
        if (page == 1) {
          setData(result.data.data.data);
        } else {
          setData(data.concat(result.data.data.data));
        }
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error('notifcation error', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      dispatch(setLoading(true));
      const result = await readAllNotification();
      console.log('read all', result);
      if (result.response.status) {
        setNoReadCount(0);
        setPage(1);
        fetchData();
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error('notifcation error', error);
    }
  };

  const notifcationNavigate = (e) => {
    if (e.type == 'ConsultationSession') {
      window.location.href = '/show/user/profile?tab=sessions'
    } else if (e.type == 'Questionnaire') {
      setShowNotification(false);
      window.location.href = `/survey/${e.model_id}`
    } else if (e.type == 'ConsultantRequest') {
      //
    } else if (e.type == 'UserRequest') {
      setShowNotification(false);
      window.location.href =  `/private/sessions/${e.consultant_user_request_id}/details/0`
    } else if (e.type == 'Course') {
      setShowNotification(false);
      window.location.href = `/lessons/${e.model_id}`
    } else if (e.type == 'BankQuestion') {
      setShowNotification(false);
      window.location.href = `/bank-questions`
    }else if( e.type == 'Certificate' ){
      setShowNotification(false);
      window.location.href = `/show/user/profile?tab=certificaties`
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationBoxRef.current && !notificationBoxRef.current.contains(event.target)) {
        // Click occurred outside the notification box
        setShowNotification(false);
      }
    };

    // Attach the event listener to the document body
    document.addEventListener('click', handleClickOutside);

   
  }, []); 

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <div
      ref={notificationBoxRef}
      className={`m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
      style={{
        background: 'rgb(255, 237, 238)',
        borderRadius: '5px',
        padding: '10px 12px 10px 10px',
        position: 'relative',
        cursor: 'pointer'
      }}>
      {' '}
      <span
        className="cart-items-number"
        style={{
          background: '#fb5456',
          position: 'absolute',
          color: '#fff',
          top: '-10px',
          right: '-5px',
          padding: '0px 7px',
          borderRadius: '50%',
          fontSize: '.9rem',
        }}>
        {noReadCount}
      </span>{' '}
      <img
        src="/icons/bell.png"
        onClick={() => {
          setShowNotification(!showNotification);
        }}
        width={25}
      />{' '}
      {showNotification && (
        <div
          className="notifications-box"
          style={{
            position: 'absolute',
            background: '#fff',
            boxShadow: '0 5px 25px rgba(34,41,47,.1)',
            borderRadius: '5px',
            padding: '10px',
            right: '0',
            marginTop: '1rem',
            height: '450px',
            width: '400px',
            overflowY: 'scroll',
            zIndex: '2',
          }}>
          {data.length && (
            <div
              style={{
                color: '#fb5456',
                textDecoration: 'underline',
                cursor: 'pointer',
                fontWeight: '500',
                marginBottom: '10px',
              }}
              onClick={() => {
                markAllAsRead();
              }}>
              Mark all as read
            </div>
          )}

          {data.map((e, i) => (
            <div
              onDoubleClick={  (e) => { e.preventDefault() } }
              onClick={async () => {
                try {
                  
                  if (e.read == 0) {
                    dispatch(setLoading(true));
                    const result = await readOneNotification(e.id);
                    console.log('rean one notifcation', result);
                    if( result.response.status ){

                    
                      dispatch(setLoading(false));
                      setNoReadCount(noReadCount - 1);

                      const newArray = data.map(obj =>
                        obj.id == e.id ? {...obj, read: 1} : obj,
                      );

                      setData(newArray);

                      

                      notifcationNavigate(e )

                    }

                  }else{
                    notifcationNavigate(e)
                  }

                } catch (error) {
                  console.error('notifcation error', error);
                }
              }}
              style={{
                padding: '1rem',
                backgroundColor: e.read == 0 ? '#e6e6e6' : '#fff',
                display: 'flex',
                borderRadius: '5px',
                color: '#000',
                marginBottom: '10px',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 4px 10px',
                position: 'relative',
              }}>
              {e.read == 0 && (
                <span
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    background: '#fb5456',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                  }}></span>
              )}

              <img
                src="/not.png"
                style={{
                  width: '50px',
                  height: '50px',
                  marginRight: '10px',
                }}
              />
              <div>
                <p
                  style={{
                    lineHeight: '1.2',
                    fontSize: '16px',
                    fontWeight: '500',
                    marginBottom: '7px',
                  }}>
                  {e.body}
                </p>
                <p
                  style={{
                    lineHeight: '1.2',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#fb5456',
                    margin: '0px',
                  }}>
                  {moment(e.created_at)
                    .locale('en')
                    .format('YYYY-MM-DD h:mm A')}
                </p>
              </div>
            </div>
          ))}
          <div>
            <div className="mt-2 mb-2">
              <Button
                className="card-button w-100"
                variant="primary"
                onClick={() => {
                  setPage(page + 1);
                  fetchData();
                }}>
                Load More
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
