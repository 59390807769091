import {EApiParams} from './apiParams.enum';

export const APIURL = {
  auth: {
    login: '/api/auth/email/login',
    sendOtp: '/api/auth/email/send-otp',
    ResendOtp: '/api/auth/email/resend-otp',
    Verify: '/api/auth/email/verify',
    Register: '/api/auth/email/register?lang=en',
    Forget: '/api/auth/email/forgot-password',
    resetPassword: '/api/auth/email/reset-password',
    refCode: `/api/mobile/referer-code`,
    userData: `/api/auth/me`,
    socialLogin: `/api/social-user/login`,
    editProfile: `/api/mobile/users/profile/`,
    deleteAccount: `/api/mobile/users/deleteacc`,
  },
  Home: {
    allData: `/api/mobile/home-new?time_zone=$${EApiParams.time_zone}`,
    getStory: '/api/stories/index',
    newHome: `/api/mobile/temp/home?time_zone=$${EApiParams.time_zone}`,
    getHomeModules: `/api/getHomeModules?lang=$${EApiParams.lang}&time_zone=$${EApiParams.time_zone}`,
  },
  tracking: {
    getAllTracking: `api/mobile/tracking/systems/paginated/list?page=$${EApiParams.page}&isMine=$${EApiParams.isMine}`,
    //getAllLevels: `api/mobile/tracking/systems/paginated/list?page=$${EApiParams.page}&isMine=$${EApiParams.isMine}&level_id=$${EApiParams.id}`,
    getTracks: `api/mobile/tracking/systems/paginated/list?page=$${EApiParams.page}&isMine=$${EApiParams.isMine}&tracking_id=$${EApiParams.id}`,
    showBeforeEnroll: `/api/mobile/single/tracking/system/details/$${EApiParams.id}`,
    showAfterEnroll: `/api/mobile/single/tracking/system/details/$${EApiParams.id}?day=$${EApiParams.date}`,
    enrollFree: '/api/trackingsystem/activities/trackingSystemEnroll',
    getActivity: `/api/mobile/single/tracking/system/activity/details/$${EApiParams.id}?day=$${EApiParams.date}`,
    addNote: `/api/trackingsystem/note/store`,
    editNote: `/api/trackingsystem/note/update`,
    updateProgressAutomatic: `/api/trackingsystem/activities/updateProgressMedia`,
    updateProgress: `/api/trackingsystem/activities/updateProgress`,
    getMemberFamily: `/api/mobile/get/members/trackings/$${EApiParams.id}`,
    assignStartTrack: `/api/trackingsystem/enroll/my/members/trackings`,
    getConsultantsCanRequest: `/api/mobile/get/consultants/enrolled/before?user_id=$${EApiParams.id}&tracking_id=$${EApiParams.tracking_id}`,
    sendRequest: `/api/trackingsystem/assign/consultants/to/track/the/tracking`,
    getEnrolledUsers: `/api/mobile/tracking/systems/$${EApiParams.id}/members/list?is_completed=$${EApiParams.type}&page=$${EApiParams.page}`,
    getRequests: `/api/trackingsystem/get/user/tracking/request?user_id=$${EApiParams.id}&tracking_id=$${EApiParams.tracking_id}`,
    repeatTrack: `/api/trackingsystem/repeat/$${EApiParams.id}`,
    getLevelsTrack: `/api/trackingsystem/get/all/levels/for/mobile/$${EApiParams.tracking_id}`,
    getMembersForAssign: `/api/mobile/get/not/assigned/members/trackings/$${EApiParams.tracking_id}`,
    getMyFollowUpSystem: `/api/mobile/getMyTrackingSystemsList`,
    getDiscoverFollowUpSystem: `/api/mobile/getTrackingSystemsDiscoverList`,
    getAllLevels: `/api/mobile/getPackageTrackingSystemDetails/$${EApiParams.id}?page=$${EApiParams.page}`,
    followUpSystemDetails: `/api/mobile/getTrackingSystemDetails/$${EApiParams.id}`,
  },
  initiative: {
    getAllInitiative: `api/mobile/tracking/systems/paginated/list?type=initiative&page=$${EApiParams.page}&isMine=$${EApiParams.isMine}`,
    showBeforeEnroll: `/api/mobile/single/tracking/system/details/$${EApiParams.id}`,
    showAfterEnroll: `/api/mobile/single/tracking/system/details/$${EApiParams.id}?day=$${EApiParams.date}`,
    enrollFree: '/api/trackingsystem/activities/trackingSystemEnroll',
    addNote: `/api/trackingsystem/note/store`,
    editNote: `/api/trackingsystem/note/update`,
    updateProgressAutomatic: `/api/trackingsystem/activities/updateProgressMedia`,
    sendProf: `/api/trackingsystem/activities/add/prof`,
    getAds: `/api/mobile/get/small/ads/$${EApiParams.type}/$${EApiParams.id}`,
    getDiscoverInitiatives: `/api/mobile/getTrackingSystemsDiscoverList?type=initiative&page=$${EApiParams.page}`,
    getMyInitiatives: `/api/mobile/getMyTrackingSystemsList?type=initiative&page=$${EApiParams.page}`,
    getAllLevels: `/api/mobile/getPackageTrackingSystemDetails/$${EApiParams.id}?page=1`,
    initiativesDetails: `/api/mobile/getTrackingSystemDetails/$${EApiParams.id}`,
    getInitiativeData: `/api/mobile/getTrackingEnrolledDateDetails/$${EApiParams.id}?user_id=$${EApiParams.family_member_id}&type=initiative`,
    getMembersForMyInitiatives: `/api/mobile/getUsersForTrackingEnrollments/$${EApiParams.id}`,
    getMembersForMyInitiativesPackages: `/api/mobile/getUsersForPackageEnrollments/$${EApiParams.id}`,
    getActivity: `/api/mobile/single/tracking/system/activity/details/$${EApiParams.id}?day=$${EApiParams.date}`,
  },
  courses: {
    getCoursesList: `/api/mobile/courses/paginated/list`,
    getCategories: '/api/mobile/get-categories',
    getCourseFeedBacks: `/api/mobile/courses/$${EApiParams.id}/feedbacks`,
    getQuestionsReviewCourse: `/api/mobile/courses/$${EApiParams.id}/questionnaire`,
    sendQestionsReview: `/api/mobile/courses/Users/questionnaires`,
    finishLesson: `/api/mobile/lessons/finish`,
    generateCertificate: `/api/mobile/certificates/course/$${EApiParams.id}`,
    sendReview: `/api/mobile/users/courses/rating-course`,
    askQuestion: `/api/mobile/bank-questions`,
    getCourseDetails: `/api/mobile/courses/single/course/$${EApiParams.id}`,
    enrollFree: `/api/mobile/users/courses/enroll`,
    getQuiz: `/api/mobile/quizzes/single/quizz/$${EApiParams.id}`,
    seenCongrats: `/api/mobile/courses/mark/course/congrats/seen/$${EApiParams.id}`,
    submitQuiz: `/api/mobile/quizzes/users`,
    submitPracticalTask: `/api/mobile/practicalTasksUser`,
  },
  tasks: {
    getAllTasks: `/api/mobile/task/index`,
    getTaskDetails: `/api/mobile/task/show/$${EApiParams.id}`,
    addNote: `/api/trackingsystem/note/store`,
    editNote: `/api/trackingsystem/note/update`,
    sendProf: `/api/trackingsystem/activities/add/prof`,
    sendAttach: `/api/mobile/task/upload/attachment`,
    taskDetails: `/api/trackingsystem/show/${EApiParams.taskId}`,
    autoUpdate: `/api/trackingsystem/activities/updateProgressMedia`,
    getAllNotes: `/api/trackingsystem/note/all-task-activity-notes`,
    getFilterData: `/api/mobile/task/filter/data`,
  },
  family: {
    getFamily: `/api/mobile/children`,
    addMembers: `/api/mobile/children`,
    editMember: `/api/mobile/children/update/$${EApiParams.childId}`,
    deleteMember: `/api/mobile/children/delete`,
  },
  experts: {
    getExperts: `/api/mobile/consultation/consultants/paginated/list`,
    getExpertData: `/api/mobile/consultation/single/consultant/$${EApiParams.id}`,
    getSurvey: `/api/mobile/consultants/$${EApiParams.id}/questionnaire`,
    getExpertTracking: `/api/mobile/tracking/systems/paginated/list/for/single/consultant?consultant_id=$${EApiParams.consultant_id}&page=$${EApiParams.page}`,
    getExpertInitiatives: `/api/mobile/tracking/systems/paginated/list/for/single/consultant?type=initiative&consultant_id=$${EApiParams.consultant_id}&page=$${EApiParams.page}`,
    getExpertCourse: `/api/mobile/courses/paginated/list/for/single/consultant?consultant_id=$${EApiParams.consultant_id}&page=$${EApiParams.page}`,
    sendMail: `/api/mobile/sendMail`,
    requestSession: `/api/mobile/consultant/requests`,
    getRequestsList: `/api/mobile/consultant/requests/index?page=$${EApiParams.page}&is_consultant=$${EApiParams.is_consultant}`,
    getRequestDetails: `/api/mobile/consultant/requests/show/details/$${EApiParams.id}`,
    acceptRequest: `/api/mobile/consultant/requests/accept/request`,
    rejectRequest: `/api/mobile/consultant/requests/reject/request`,
    getSlots: `/api/mobile/consultationInfoDays`,
    getSessions: `/api/mobile/consultationSessions?page=$${EApiParams.page}`,
    attendConsSess: `/api/mobile/consultationSessions/$${EApiParams.id}/update/attended`,
    reqeustConsultant: `/api/mobile/consultationInfos/requests/store`,
    getConsultantProfile: `/api/mobile/consultationInfos/requests/$${EApiParams.id}`,
    editProfile: `/api/mobile/consultationInfos/requests/$${EApiParams.id}`,
    getUserSurveyList : `/api/mobile/consultants/my-questionnaires`
  },
  general: {
    getLang: `/api/setting/languages`,
    getFields: `/api/setting/fields/index/$${EApiParams.type}`,
    getBalance: `/api/wallet/user/balance`,
    getMeetLink: `/api/mobile/replaceLinkForMeeting`,
    getJobTitles: `/api/mobile/job-title/index`,
    contactUs: `/api/setting/contactUs`,
    getSurveyMeeting: `/api/mobile/consultation/sessions/$${EApiParams.id}/get`,
  },
  notifications: {
    getAllNotifications: `/api/mobile/notifications/users/optimize?page=$${EApiParams.page}`,
    readOne: `/api/mobile/notifications/users/$${EApiParams.id}`,
    readAll: `/api/notifications/marks/all/as/read`,
  },
  availability: {
    getCurrency: `/api/availability/get-multi-currency`,
    setAvailabilityStatus: `/api/availability/disable-consultant`,
    getAvailabilityStatus: `/api/availability/get-disable-consultant`,
    getAvailability: `/api/availability/index/new?date=$${EApiParams.date}`,
    deleteSlotsByIds: `/api/availability/delete/new/$${EApiParams.id}`,
    getSpecAvalability: `/api/availability/index/start/filter?start_time=$${EApiParams.time}&duration=$${EApiParams.duration}`,
    deleteRange: `/api/availability/delete/new/start/end/range?start=$${EApiParams.start}&end=$${EApiParams.end}`,
    setAvailability: `/api/availability/add/new`,
    setPrices: `/api/availability/multi-currency`,
  },
  booking: {
    bookSession: `/api/availability/available/sessions/filter/by/time?start_time=$${EApiParams.start}&duration=$${EApiParams.duration}&consultant_id=$${EApiParams.id}`,
    bookFree: `/api/mobile/free/consultation/session/enrollment`,
    getSessionsUser: `/api/mobile/consultationSessions?page=$${EApiParams.page}`,
    cancelSession: `/api/cancel/transaction`,
    addRate: `/api/mobile/consultants/rating`,
    submitSurvey: `/api/mobile/courses/Users/questionnaires`,
  },
  cart: {
    add: `/api/cart/add`,
    get: `/api/cart/list`,
    delete: `/api/cart/delete/$${EApiParams.id}`,
  },
  checkout: {
    applyCoupon: `/api/cart/apply-coupon`,
    placeOreder: `/api/payment/checkout`,
  },
  survey: {
    submit: `/api/mobile/questionnaires/consultants/users/answer`,
  },
  bankQ: {
    getBankQuestions: `/api/mobile/bank-questions/index?is_nurabi=1`,
    like: `/api/mobile/bank-questions-user/$${EApiParams.id}`,
    watch: `/api/mobile/bank-questions/$${EApiParams.id}/show`,
  },
};
