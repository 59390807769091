import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {TrackingItemProps} from '../interfaces/ITrackings';
import classes from '../style/TrackingCard.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserId} from '../../../auth/utiles';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
const TrackingCard = ({
  tracking,
  isPackage,
  enrolled,
  is_discover,
}: TrackingItemProps) => {
  const location = useLocation();

  const navigate = useNavigate();

  const userId = getUserId() ?? '';

  const queryParams = new URLSearchParams(location.search);

  const [discover, setDiscover] = useState<string>('0');

  useEffect(() => {
    const tabFromQueryParam = queryParams.get('tab');
    if (tabFromQueryParam && tabFromQueryParam == 'nurabiTrackingSystems' ) {
      setDiscover('1')
    }
  }, [location.search]);


  return (
    <div
      className={`${classes.Item} d-flex row`}
      style={tracking.lang === 'ar' ? { direction: 'rtl' , 'textAlign' : 'right' } : {}}
      onClick={() => {
        if( isPackage ){
          navigate(`/user/initiatives/${tracking.id}/package/levels?discover=${discover}`);
        }else{
          if( (tracking.tracking_type && tracking.tracking_type == 'single') || tracking.level ){

            if( tracking.selected_enrolled_user_id && is_discover != 1 ){
              navigate(`/user/initiative/${tracking.id}/details/enrolled/${tracking.selected_enrolled_user_id}`,
              
              {
                state: {
                  start_date: tracking.start_date,
                  end_date: tracking.end_date,
                  item: tracking,
                },
              });
            }else{
              navigate(`/user/initiatives/${tracking.id}/details?is_level=${ tracking.level ? '1' : '0' }`);
            }
          }
        }
      }}
     
      >
      <h5 className="font-weight-bold p-0 mb-3">{tracking.name}</h5>
      
      {
        isPackage
        ? 
        <div className={`${classes.Owner}`} style={tracking.lang === 'ar' ? { right: 'initial' , left : '0px' , borderRadius: '10px 0px' } : {}} >levels</div>
        :
        ''
      } 
     
      <div className={`${classes.Image} col-3`}>
      <img
          src={tracking.image ? tracking.image : '/nurabi.png'}
          alt="course-img"
          style={{
            width: tracking.image ? '180px' : '100px',
            height: tracking.image ? '160px' : 'auto',
          }}
        />
      </div>
      <div
        className={`${classes.Details} col-8 d-flex flex-column justify-content-between`}>
        <div style={{margin: '0 5px'}}>
          <p style={{ color : 'gray' }}>{tracking.short_description}</p>

          <h4 className={`${classes.Provider}`} style={tracking.lang === 'ar' ? { direction: 'rtl' } : {}}>
          {tracking.provider_name ? `${tracking.provider_name}` : `Nurabi`}{' '}
          </h4>

          { !enrolled && tracking.level == undefined ?
            <div className={`${classes.Price} mb-3`}>
                      {tracking.price == 0 ? 'Free' : `${tracking.price} $`}
                    </div>
          :
          ''
          }
          

          {
            !isPackage
            
            ?

          
          <div className="Date d-flex align-items-center pl-3 pr-3">
            <div className="Start">
              <p className="m-0 d-flex align-items-center" style={{color: '#959494'}}>
                <FaRegCalendarAlt style={{marginRight: '5px'}} /> Start{' '}
              </p>
              <p>{tracking.start_date}</p>
            </div>
            <div className={`${classes.ArrowDate}`}>
              <FaLongArrowAltRight color="#07b2bd" />
            </div>
            <div className="End">
              <p className="m-0 d-flex align-items-center" style={{color: '#959494'}}>
                <FaRegCalendarAlt style={{marginRight: '5px'}} /> End
              </p>
              <p>{tracking.end_date}</p>
            </div>
          </div>
          :
          
          ''

          }

          {
          
            tracking.enrolled_members
            
            ?
              
            <div className="d-flex text-center">
              {
              tracking.enrolled_members.length > 0
            
                ? tracking.enrolled_members.map((item, index) => (
                    <div key={index} style={{margin: '0 10px'}}>
                      <img
                        src={item.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '20px',
                          height: '25px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                      <h6>
                        {item.username.length > 7
                          ? item.username.substring(0, 7) + ' ...'
                          : item.username}
                      </h6>
                    </div>
                  ))
                : ''}
            </div>

            : 
            
            ''
          
          }

          
          
        </div>
        
      </div>
    </div>
  );
};

export default TrackingCard;
