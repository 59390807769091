import {Col, Container, Row} from 'react-bootstrap';
import ShoppingCartItem from './components/ShopingCartItem';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ShoppingCart.module.css';
import {useAppSelector} from '../../hooks/hooks';
import {useNavigate} from 'react-router';
import {colors} from '../../constants/colors';
import {useState , useEffect} from 'react';

const ShoppingCart = () => {
  const navigate = useNavigate();
  const cartData = useAppSelector(state => state.cart);
  const [checkoutIsDisabled, setCheckoutIsDisabled] = useState(false);
  const checkCartData = () => {
    const hasSoldOutProduct = cartData.data.some(product => product.is_soldout == 1);

    if (hasSoldOutProduct) {
      console.log('At least one product is sold out.');
      setCheckoutIsDisabled(true)
    } else {
      console.log('No product is sold out.');
    }
  }
  useEffect(() => {
    checkCartData()
  }, );
  return (
    <Container
      fluid
      className={`${classes.ShoppingCartContainer}`}>
      <Row>
        <h1 className={`main-title p-0 mt-3 ${classes.ShoppingCartTitle}`}>Shopping Cart</h1>
        <Col md={8}>
          <div className={`w-100 ${classes.CartDetails} `}>
            <div className="Items">
              {cartData.data.map(i => (
                <ShoppingCartItem data={i} />
              ))}
            </div>
          </div>
        </Col>

        <Col md={4} className={`${classes.CheckoutDetails}`}>
          <div>
            <h6>Number Of Products:</h6>
            <span>{cartData.totalProducts}</span>
          </div>

          <hr />

          <div>
            <h6>Total:</h6>
            <span>{cartData.totalPrice} USD</span>
          </div>
          <div className="mt-4">
            <MainButton
              disabled={checkoutIsDisabled}
              text="Checkout"
              style={{
                backgroundColor:
                  (cartData.data.length != 0 && checkoutIsDisabled == false ) ? colors.maincolor : colors.gray,
                borderColor:
                  (cartData.data.length != 0 && checkoutIsDisabled == false ) ? colors.maincolor : colors.gray,
              }}
              onClick={() => {
                if (cartData.data.length != 0) navigate(`/checkout?total=${cartData.totalPrice}`);
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ShoppingCart;
